import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';

import TermsSection from '../components/CommonSections/TermsSection';

const PrivacyPage = () => (
  <>
    <SEO title="Terms and Conditions" />
    <TermsSection 
      title="Terms and Conditions"
      content={(<SecondSection />)}
    />
  </>
);

const SecondSection = () => (
    <>
      <h2>Mortgage quote and access to an advisor service Terms and Conditions</h2>
      <p>
        This page (together with our <Link to="/privacy">Privacy Policy</Link>, which can be found on our site) tells you
        information about us and the legal terms and conditions ("Terms") on which we provide you with access to to an advisor/broker 
        who may be able to help you with your enquiry and by submitting your details you agree to be contacted by telephone or
        email by the advisor. Our service is free for you to use, however we receive commission and/or fees from brokers and service providers who we refer you to.
      </p>

      <p>
        Our aim is to always provide you with an excellent service. However if you are unhappy with any product or 
        advisory service that you have been introduced to through our website, you should reach out to regulated firm 
        or advisor who provided you that product or service directly. If you require any provider’s contact details, 
        please contact us by emailing us at <a href="mailto:hello@sprive.com" type="email">
              hello@sprive.com
          </a> and we will be happy to assist.
      </p>

      <p>
        By accepting these T&Cs, you agree that Sprive Limited or Connect IFA Ltd shall not be held liable for any 
        mortgage advice provided by a third-party firm introduced through our website.
      </p>

      <p>
        These Terms will apply to your use of Sprive and the provision of our services to
        you. Please read these Terms carefully and make sure that you understand them,
        before using this service via our website. Please note that before you use Sprive you
        will be asked to agree to these Terms. You must not use this website if you do not
        agree to the terms and conditions, are under 18 or are a non-UK resident. If you
        refuse to accept these Terms, you will not be able to use this service via our website.
      </p>

      <p>
        We amend these Terms from time to time as set out in clause 5. 
        Please check these Terms periodically to ensure you understand the terms which will apply at that time. 
        You may ask for a copy of the then current Terms at any time.
      </p>

      <p>These Terms and all information will be in the English language.</p>

      <h4>1. Information about us</h4>

      <p>
        <ol>
          <h4>1.1 Who we are</h4>
          <p>
            We are Sprive Limited, a company registered in England and Wales under company number
            12102682 and our registered office is at Cannon Place, 78 Cannon Street, 
            London, EC4N 6AF. We operate the website <a
              href="https://sprive.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://sprive.com
        </a> and the services available
            through our App.
          </p>
          <h4>1.2 Our regulatory status</h4>
          <p>
          Sprive Limited (FRN: 919863) is FCA registered and we’re an appointed representative of
            Connect IFA Ltd for the purposes of agreeing to carry on a regulated activity,
            arranging regulated mortgage contracts; making arrangements with a view to regulated
            mortgage contracts; and transactions in investments for standard protection insurance. 
            Connect IFA Ltd is authorised and regulated
            by the Financial Conduct Authority (FRN: 441505) and its registered office is
            at 39 Station Lane, Hornchurch, Essex, RM12 6JL, United Kingdom.
          </p>
          <p>
            Sprive Limited is also a registered agent of Prepay Technologies Ltd which is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 (FRN: 900010) for the issuing of electronic money.
          </p>

          <h4>1.3 Contacting us</h4>
          <p>
            If you wish to contact us for any reason, including because you have any complaints,
            you can contact us by emailing us at <a href="mailto:hello@sprive.com" type="email">
              hello@sprive.com
          </a>.</p>
          <p>
            If we have to contact you or give you notice in writing, we will do so by email,
            using details you provide to us.
          </p>
          
          <h4>1.4 Security incidents</h4>
          <p>
            If we become aware or suspect that you are, “or have been affected” by fraud or an operational security incident, we will contact you via email.
          </p>
        </ol>
      </p>

      <h4>2. Our service</h4>
      <p>
        <ul>
          <p>
            1. You must be over 18 years old to use this service and have a mortgage within the UK.
          </p>
          <p>
            2. Our service allows you to be introduced to an advisor/broker who may be able to
            help you with your enquiry and by submitting your details you agree to be contacted
            by telephone or email by the advisor. Our service is free for you to use, however we
            receive commission and/or fees from brokers and service providers who we refer
            you to.          
          </p>
        </ul>
      </p>
      
      <h4>3. Financial advice</h4>
      <p>
        <ul>
          <p>1. No information provided on our site is intended to constitute, mortgage, tax or legal advice or any other type of advice, nor does it recommend   particular products or services. Any reliance that you may place on the information on our site is at your own risk.</p>
          <p>2. You should obtain professional or specialist advice before taking, or refraining from, any action on the basis of the information on this site.</p>
          <p>3. While we aim to keep the information on our site accurate, complete, and up-to-date, we cannot guarantee, and will not be responsible for any damage or loss related to, the accuracy, completeness or timeliness of the information. Accordingly, we will not be liable in respect of any losses you may suffer arising out of such information or any reliance you may place upon such information.</p>
          <p>4. You are solely responsible for the accuracy and reliability of any information or materials uploaded by you through the app and our site and you hereby acknowledge and agree that we will not be responsible, or liable to any third party, for the content or accuracy of any information or materials submitted by you through the app or our site.</p>
        </ul>
      </p>
      
      <h4>4. How we use your personal information</h4>
      <p>We only use your personal information in accordance with our <Link to="/privacy">Privacy Policy</Link> which can be accessed through Sprive. Please take the time to read our <Link to="/privacy">Privacy Policy</Link> as it includes important terms which apply to you.</p>

      <h4>5. Our right to vary these Terms</h4>
      <p>
        <ul>
          <p>
            1. We expect to update or amend these Terms from time to time to comply with law or to meet our changing business requirements.
          </p>
          <p>
            2. Please check this page from time to time to take notice of any changes we made, as they are binding on you.
          </p>
        </ul>
      </p>

      <h4>6. Changes to our site</h4>
      <p>We may update Sprive from time to time, and may change the content at any time. However, please note that any of the content on Sprive may be out of date at any given time, and we are under no obligation to update it.</p>
      <p>If we change these terms of use, we will post the revised document here with an updated effective date. If we make significant changes to these terms of use, we may also notify you by other means such as sending an email, or posting a notice on our website.</p>
      <p>We do not guarantee that our site, or any content on it, will be free from errors or omissions.</p>

      <h4>7. Accessing this service</h4>
      <p>Our site is available free of charge</p>
      <p>We do not guarantee this service, or any content on it, will always be available or be uninterrupted. Access to our site is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of Sprive without notice. We will not be liable to you if for any reason this service is unavailable at any time or for any period. We reserve the right to restrict or deny you access to all or some parts of this service if, in our opinion, you have failed to comply with these terms of use.</p>
      <p>You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>
      <p>This service is directed to people residing in the United Kingdom. We do not represent that content available on or through Sprive is appropriate or available in other locations. We may limit the availability of our site or any service or product described on Sprive to any person or geographic area at any time.</p>
      
      <h4>8. Intellectual property rights</h4>
      <p>We are the owner or the licensee of all intellectual property rights in relation to Sprive, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
      <p>You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>
      <p>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
      <p>Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>
      <p>You must not use any part of the content on Sprive for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
      <p>If you print off, copy or download any part of Sprive in breach of these terms of use, your right to use Sprive will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>

      <h4>9. No reliance on information</h4>
      <p>The content on Sprive is provided for general information only. It is not intended to amount to financial advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on Sprive.</p>
      <p>Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up-to-date.</p>

      <h4>10. Limitation of our liability</h4>
      <p>Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.</p>
      <p>To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our site or any content on it, whether express or implied.</p>
      <p>We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with this service. We are not responsible or liable for any loss or damage you may suffer or incur in connection with your failure to ensure such information is correct and not misleading.</p>
      <p>As a consumer using Sprive, please note that we only provide Sprive for domestic and private use. You agree not to use Sprive for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
      <p>We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site or to your downloading of any content on it, or on any website linked to it.</p>
      <p>We assume no responsibility for the content of websites linked to from Sprive. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.</p>
      <p>Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any services to you, which will be set out in the Sprive Terms and Conditions.</p>

      <h4>11. Viruses</h4>
      <p>We do not guarantee that Sprive will be secure or free from bugs or viruses.</p>
      <p>You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should use your own virus protection software.</p>
      <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use Sprive will cease immediately.</p>

      <h4>12. Linking to Sprive</h4>
      <p>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
      <p>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
      <p>You must not establish a link to Sprive in any website that is not owned by you.</p>
      <p>Sprive must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
      <p>We reserve the right to withdraw linking permission without notice.</p>
      <p>If you wish to make any use of content on Sprive other than that set out above, please contact <a href="mailto:hello@sprive.com" type="email">
        hello@sprive.com
          </a></p>

      <h4>13. Validity</h4>
      <p>If any part or provision of these terms of use are found to be unlawful or unenforceable, this shall not affect the validity of any other part or provision.</p>

      <h4>14. Third party links and resources in our site</h4>
      <p>Where Sprive contains links to other sites and resources provided by third parties, these links are provided for your information only.</p>
      <p>We have no control over the contents of those sites or resources.</p>

      <h4>15. Additional Terms &amp; Conditions</h4>
      <p>If you provide us with any feedback, you are solely responsible for ensuring that all the information you provide is correct, complete, accurate and not misleading and that you disclose all relevant facts required. We are not responsible or liable for any loss or damage you may suffer or incur in connection with your failure to ensure such information is correct and not misleading.</p>
      <p>You are responsible for ensuring that any third party that you wish to contract with has all the important information about you.</p>
      <p>It is very important to ensure that the information held by the third party is correct, complete and not misleading and you have disclosed all the relevant facts. It’s your responsibility to identify any mistakes or errors in the information, content, material or data held about you before you obtain any product or service from the third party. Failure to do so could invalidate the product or service provided by the third party.</p>
      <p>We are not responsible or liable for any loss or damage suffered as a result of your failure to ensure such information is correct and not misleading.</p>
      <p>You are responsible for ensuring that you understand and agree with the terms and conditions of the third party that will be providing the product or service to you.</p>
      <p>Through our website, you can apply for a number of products and services. These products and services are all provided by third parties and not us. If you apply for a product or service, you will be contracting with a third party that will supply that product or service to you on their own terms and conditions. It is your responsibility to understand and agree with the terms and conditions which relate to the specific product or service you are interested in before you enter into that contract. We are not responsible or liable to you for any loss or damage you may suffer or incur in connection with any contract entered into by you with any third party in relation to any product or service.</p>
      <p>You are responsible for making your own enquiries to check that the product or service that you want to apply for is accurate, complete and up-to-date and meets your needs.</p>
      <p>Our website contains information and descriptions about the products and services you may apply for. This information does not provide a complete description of all the features, terms, conditions, or information regarding those products. Much of this information is provided by third party product or service providers. We do not verify or endorse the information as it may be inaccurate, incomplete or out of date. Although we reasonably endeavour to ensure the information on our website is reliable and try to correct any errors and omissions as soon as possible, it is your responsibility to ensure that such information is accurate. We are not responsible or liable to you for any loss or damage you may suffer or incur in connection with, the details we provide on our website with respect to any product or service provided by third parties which you did not check individually and/or do not meet your needs for any reason.</p>

      <h4>16. Applicable law</h4>
      <p>As a consumer, please note that these terms of use, its subject matter and its formation, are governed by English law. If you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland. You and we agree that the courts of England will otherwise have exclusive jurisdiction.</p>

      <h4>17. Contact us</h4>
      <p>To contact us, please email <a href="mailto:hello@sprive.com" type="email">
        hello@sprive.com
          </a>.</p>
      <p>Thank you for visiting Sprive.</p>
    </>
);

export default PrivacyPage;
